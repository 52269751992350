
export default {
  props: {
    image: {
      type: Object,
      default() {},
    },
    lazy: {
      type: Boolean,
      default: true
    },
  },
}

// utils/tracking.js
// Örnek URL :
//domain.com?gclid=Cj0KCQiAwP6sBhDAARIsAPfK_wZ2dvdM0ose_IBM-JzUK7sforu11Y5XpvcD_idcMLFKQUpaJbMFE4oaAm5XEALw_wcB&utm_term=bar_term&utm_campaign=bar_campaign&utm_source=bar_source&fbid=123445&utm_content=bar_content&utm_medium=bar_medium


  function setupTracking(urlParams, cookies) {
    // console.log("setupTracking");
      if(process.env.NODE_ENV !== 'production'){
        // console.log("Tracking setup started.");
      }
      const watchQueryParams = ["utm_source", "utm_medium", "utm_term", "utm_campaign", "utm_content", "gclid", "fbid"];
      const baseDomainRegex = /\b(mikro\.com\.tr)\b/;
      // Set a cookie that expires on a specific date
      let expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 365);
      const cookieExpireDays = expiryDate;

  
      function getSourceMedium() {
        const utm_campaign = urlParams.get("utm_campaign");
        const utm_source = urlParams.get("utm_source");
        const searchEngines = ["google.com", "bing.com", "yandex.com", "duckduckgo.com"];

        let source = "direct";
  
        if ( utm_campaign || utm_source  ) {
          // Kampanya trafigi
          source = utm_campaign + '/' + utm_source;

        } else if (searchEngines.some(url => document.referrer.includes(url))) {
          // Gogole veya Serach engine trafigği
          source = "organic";

        } else if (document.referrer && !document.referrer.match(baseDomainRegex)) {
          // Gogole veya internal domain harifi referans veren diger URL ler 
          source = document.referrer;
        }

        return source;
      }
  

      function setCookieIfNotSet(key, value) {
        if (!cookies.get(key)) {
            // console.log("Set Cookies first time!:", key,value);
          cookies.set(key, value, { expires: cookieExpireDays });
        }else{
        //   console.log("Cookie already set:", key, cookies.get(key));
        }
      }

      const source_first = cookies.get("source_first");
      // Set initial campaign and source
      if ( source_first === undefined || source_first === "undefined" || source_first === "") {
        cookies.set("source_first", getSourceMedium(), { expires: cookieExpireDays });
        //setCookieIfNotSet("source_first",getSourceMedium());
      }


      // Set last campaign and source if not internal navigation
      // setCookieIfNotSet("source_last",getSourceMedium());
      cookies.set("source_last", getSourceMedium(), { expires: cookieExpireDays });

  
      // Set all watch parameters
      watchQueryParams.forEach(param => {
        let paramValue = urlParams.get(param);
        if (paramValue) {
            // console.log("Cookie.set:", param, paramValue);
          cookies.set(param, paramValue, { expires: cookieExpireDays });
        }
      });
   /*
      // Set Google Click ID related data if available
      if (urlParams.has("gclid") || urlParams.has("utm_source")) {
        let gclidData = {};
        watchQueryParams.forEach(param => {
          let paramValue = urlParams.get(param);
          if (paramValue) {
            gclidData[param] = paramValue;
          }
        });
  
        cookies.set("gclid_data", JSON.stringify(gclidData), { expires: cookieExpireDays });
      }*/
  
      // Data Layer for Google Tag Manager
      window.dataLayer = window.dataLayer || [];
      let dataLayerObject = {
        source_first: cookies.get("source_first"),
        source_last: cookies.get("source_last"),
        utm_source: cookies.get("utm_source"),
        utm_medium: cookies.get("utm_medium"),
        utm_campaign: cookies.get("utm_campaign"),
        utm_term: cookies.get("utm_term"),
        utm_content: cookies.get("utm_content"),
        gclid: cookies.get("gclid"),
        fbid: cookies.get("fbid"),

        
      };

 
      window.dataLayer.push(dataLayerObject);

      if(process.env.NODE_ENV !== 'production'){
        // console.log("Data Layer:", window.dataLayer);
      }

      
    } // .setupTracking

    // get user data from cookies
  function getUserData(cookies) {
    // console.log("getUserData");
      return {
        // campaign_first: cookies.get("campaign_first"),
        source_first: cookies.get("source_first"),
        source_last: cookies.get("source_last"),
        campaign_last: cookies.get("campaign_last"),
        utm_source: cookies.get("utm_source"),
        utm_medium: cookies.get("utm_medium"),
        utm_campaign: cookies.get("utm_campaign"),
        utm_term: cookies.get("utm_term"),
        utm_content: cookies.get("utm_content"),
        gclid: cookies.get("gclid"),
        fbid: cookies.get("fbid"),
      };
  } // .getUserData

  
  export { setupTracking, getUserData };
  